import React from 'react';
import { RawTutorialStep } from '../../types';

export const settings: RawTutorialStep[] = [
  {
    id: 'settings-page',
    route: '/settings',
    content: {
      title: 'Dane do faktury',
      text: [
        'Jeśli chcesz poprawić dane do faktury lub zmienić konto z indywidualnego na konto firmowe użyj ',
        'przycisku „Popraw dane”.',
      ],
    },
    target: {
      selector: '#settings-correct-data-button',
      padding: { bottom: -5 },
    },
  },
  {
    route: '/settings',
    content: {
      title: 'Ważność konta',
      text: ['Tu sprawdzisz datę wygaśnięcia Twojego konta.'],
    },
    target: {
      selector: '#settings-account-expires-at',
      padding: { bottom: -14 },
    },
  },
  {
    route: '/settings',
    content: {
      title: 'Subkonta',
      text: [
        'Tu utworzysz subkonta (max.2) ',
        'oraz przydzielisz im dostęp do placówek,',
        ' bazy produktów, potraw i jadłospisów. ',
      ],
    },
    target: {
      selector: '#settings-sub-accounts-sections',
      padding: 3,
    },
  },
  {
    route: '/settings',
    content: {
      title: 'Dziękujemy za skorzystanie z Samouczka.',
      text: [
        'Dzięki niemu dowiedziałaś/eś się jak od razu rozpocząć pracę w aplikacji Intendent Pro.',
        'W razie pytań pozostajemy do dyspozycji.',
        '',
        'Zespół Intendent Pro',
      ],
    },
  },
];

import React from 'react';
import { RawTutorialStep } from '../../types';

export const extendAccess: RawTutorialStep[] = [
  {
    id: 'extend-access-page',
    route: '/cart/create',
    content: {
      text: ['Tu przedłużysz swój miesięczny lub roczny dostęp do aplikacji Intendent Pro.'],
    },
  },
  {
    route: '/cart/create',
    content: {
      text: [
        <span>
          <b className="font-semibold ">Wersja standardowa:</b> dedykowana 1 jednostce żywienia zbiorowego (tj. żłobek,
          zespół szkolno- przedszkolny)
        </span>,
        <span>
          <b className="font-semibold ">Wersja profesjonalna:</b> dedykowana jednostkom żywienia zbiorowego/ dietetykom/
          firm cateringowych świadczącym usługi dla wielu placówek (powyżej 3), dla których należy wyliczyć normy
          żywieniowe.
        </span>,
      ],
    },
  },
  {
    route: '/cart/create',
    content: {
      text: [<span className="font-semibold text-primary-500">Kliknij w przycisk „Ustawienia”</span>],
    },
    target: {
      selector: '#settings-link',
      padding: 3,
    },
    canGoNext: false,
  },
];

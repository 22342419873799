import React from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';

export interface Props {
  id?: string;
  type?: 'button' | 'reset' | 'submit' | undefined;
  width?: number | 'auto' | undefined;
  marginRight?: number;
  marginBottom?: number;
  justify?: string;
  testId?: string;
  label?: string;
  option: string;
  onClick?: (e: React.MouseEvent) => void;
  item?: any;
  icon?: React.ReactElement;
  zIndex?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export default function Button(props: Props) {
  const {
    width,
    marginRight,
    marginBottom,
    justify,
    option,
    testId,
    item,
    label,
    icon,
    type,
    zIndex,
    ...buttonProps
  } = props;

  const __styles = cx(styles.btn, {
    [styles.btnPrimary]: option === 'primary',
    [styles.btnSecondary]: option === 'secondary',
    [styles.btnSmall]: option === 'small',
    [styles.btnAlert]: option === 'alert'
  });

  return (
    <button
      {...buttonProps}
      className={__styles}
      type={type ?? 'submit'}
      style={{
        width: width ?? '100%',
        marginRight: marginRight ?? 0,
        marginBottom: marginBottom ?? 0,
        justifyContent: justify ?? 'center',
        zIndex: zIndex ?? 0,
        ...props.style
      }}
      data-testid={testId}
    >
      {icon && React.createElement(icon, { testId: `${testId}-icon` })}
      {label && (
        <span
          data-testid={testId && `${testId}-span`}
          className={cx({ [styles['label--with-icon']]: icon })}
        >
          {label}
        </span>
      )}
    </button>
  );
}

import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const menuCompositionItem: RawTutorialStep[] = [
  {
    id: 'menu-composition-meal-item-order-buttons',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Strzałki',
      text: [
        'zmieniają kolejność potraw i produktów. ',
        'Estetyczne ułożenie dań na jadłospisie będzie lepiej wyglądało na wydruku.',
      ],
    },
    target: {
      selector: '#menu-composition-meal-item-order-buttons',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
    previous: 'culinaryset-delete-meal',
  },
  {
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Opcje potrawy',
      text: [<span className="text-primary-500">Kliknij w przycisk Opcji (3 kropki).</span>],
    },
    target: {
      selector: '#menu-composition-item-dish-1-dropdown-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ unlockDropdown, targetElement, nextStep }) => {
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });
      unlockDropdown();

      return () => {
        unsubscribe();
      };
    },
    canGoNext: false,
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Podgląd',
      text: ['Przejście do karty potrawy.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-preview-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Kopiuj',
      text: ['Kopiowanie potrawy do innego dnia.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-copy-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Przenieś',
      text: ['Przeniesienie potrawy do innego dnia.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-transfer-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Zamień',
      text: ['Zamiana potraw między sobą.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-change-to-another-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Wymień',
      text: ['Wymiana potrawy na inną.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-change-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Dodaj składnik',
      text: ['Dodawanie nowego składnika do potrawy.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-add-ingredient-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Zapamiętaj',
      text: [
        'Jeśli zmienisz gramatury składników w potrawie, dodasz lub usuniesz składnik i chcesz, aby program zapamiętał potrawę w takiej formie, kliknij „Zapamiętaj”. ',
        'Program zapisze nową recepturę w Twojej Bazie własnej.',
        'Nie musisz już jej poprawiać w zakładce Potrawy.',
      ],
    },
    target: {
      selector: '#menu-composition-dropdown-item-save-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Utwórz nową',
      text: [
        'Jeśli w wybranej potrawie chcesz zmienić więcej parametrów niż tylko gramtury, ',
        'wybierz funkcję „Utwórz nową”.',
        'Program przeniesie Cię edycji potrawy, gdzie naniesiesz swoje zmiany. ',
        'Koniecznie zmień nazwę potrawy.',
        <b className="font-semibold ">
          Ta funkcja umożliwia szybkie dodanie nowej potrawy do jadłospisu i do Bazy własnej.
        </b>,
      ],
    },
    target: {
      selector: '#menu-composition-dropdown-item-create-new-dish-from-meal-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Zamiennik alergenu',
      text: ['Wybór potrawy alternatywnej spełniającej kryteria diety.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-change-alternative-item-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-item-dish-1-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Usuń',
      text: ['Usunięcie potrawy z jadłospisu.'],
    },
    target: {
      selector: '#menu-composition-dropdown-item-delete-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-subitem-0-0-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Opcje składnika potrawy',
      text: [<span className="text-primary-500">Kliknij w przycisk Opcji (3 kropki).</span>],
    },
    target: {
      selector: '#menu-composition-subitem-0-0-dropdown-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ unlockDropdown, targetElement, nextStep }) => {
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });
      unlockDropdown();

      return () => {
        unsubscribe();
      };
    },
    canGoNext: false,
  },
  {
    dropdownId: 'menu-composition-subitem-0-0-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Podgląd',
      text: ['Przejście do karty produktu.'],
    },
    target: {
      selector: '#menu-composition-subitem-dropdown-item-view-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-subitem-0-0-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Wymień',
      text: ['Wymiana składnika na inny.'],
    },
    target: {
      selector: '#menu-composition-subitem-dropdown-item-change-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-composition-subitem-0-0-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Usuń',
      text: ['Usunięcie składnika z potrawy.'],
    },
    target: {
      selector: '#menu-composition-subitem-dropdown-item-delete-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    id: 'preview-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Podgląd jadłospisu',
      text: [
        'Aby zobaczyć cały jadłospis, kliknij w przycisk ',
        <span className="text-primary-500">„Podgląd”</span>,
        '.',
      ],
    },
    target: {
      selector: '#preview-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();

      return () => null;
    },
    next: 'menu-preview-page',
    canGoNext: false,
  },
  {
    id: 'preview-button-no-valid-menu',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Podgląd jadłospisu',
      text: [
        'Aby zobaczyć cały jadłospis, kliknij w przycisk ',
        <span className="text-primary-500">„Podgląd”</span>,
        '.',
      ],
    },
    target: {
      selector: '#preview-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();

      return () => null;
    },
    previous: 'culinaryset-delete-meal-no-valid-meal',
    canGoNext: false,
  },
];

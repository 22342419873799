import { RawTutorial, RawTutorialStep, TargetPadding, TargetPositioning, Tutorial, TutorialStep } from '../types';
import { linkTutorialSteps } from './linkTutorialSteps';

export function standardizeTutorials(tutorials: RawTutorial[]): Tutorial[] {
  return tutorials.map((tutorial) => {
    const tutorialName = tutorial.name;
    const standardizedSteps: TutorialStep[] = tutorial.steps.map((step, index) =>
      standardizeStep({ step, index, tutorialName })
    );

    const linkedSteps = linkTutorialSteps(standardizedSteps);

    return {
      name: tutorialName,
      steps: linkedSteps,
      isMultiPage: tutorial.isMultiPage ?? false,
    };
  });
}

const standardizeStep = ({
  step,
  index,
  tutorialName,
}: {
  step: RawTutorialStep;
  index: number;
  tutorialName: string;
}): TutorialStep => {
  const id = step.id ?? `${tutorialName}-step-${index + 1}`;

  const positioning: TargetPositioning = {
    align: step?.positioning?.align ?? 'center',
    justify: step?.positioning?.justify ?? 'right',
    gap: step?.positioning?.gap ?? 25,
  };

  const padding = normalizePadding(step?.target?.padding);

  const {
    route,
    target,
    showAsFirstStep = false,
    canGoNext = true,
    canGoPrevious = true,
    skipSaveState = false,
  } = step;

  return {
    ...step,
    id,
    route: Array.isArray(route) ? route : [route],
    target: target
      ? {
          selector: target.selector,
          padding,
          focus: target.focus ?? false,
          ignoreNavbarOffset: !!target.ignoreNavbarOffset,
        }
      : undefined,
    positioning: positioning,
    showAsFirstStep,
    canGoNext,
    canGoPrevious,
    skipSaveState,
    url: null,
  };
};
const normalizePadding = (
  padding?: number | { top?: number; bottom?: number; left?: number; right?: number }
): TargetPadding => {
  const defaultPadding = 10;
  if (typeof padding === 'number') {
    return {
      top: padding,
      bottom: padding,
      left: padding,
      right: padding,
    };
  } else if (typeof padding === 'object' && padding !== null) {
    return {
      top: padding.top ?? defaultPadding,
      bottom: padding.bottom ?? defaultPadding,
      left: padding.left ?? defaultPadding,
      right: padding.right ?? defaultPadding,
    };
  } else {
    return {
      top: defaultPadding,
      bottom: defaultPadding,
      left: defaultPadding,
      right: defaultPadding,
    };
  }
};

import { RawTutorial } from '../types';

import { institutionCard } from './steps/institutionCardPage';
import { institution } from './steps/institutionPage';
import { main } from './steps/mainPage';
import { menuComposition } from './steps/menuCompositionSteps';
import { menuEditing } from './steps/menuEditingSteps';
import { menus } from './steps/menusPage';
import { newInstitution } from './steps/newInstutionPage';
import { singleMenuItem } from './steps/singleMenuItemSteps';
import { removedMenus } from './steps/removedMenusPage';
import { menuCompositionItem } from './steps/menuCompositionItem';
import { menuPreviewPage } from './steps/menuPreviewPage';
import { alternativeInstitutionsPage } from './steps/alternatieInstitutionPage';
import { editInstitution } from './steps/editInstutionPage';
import { profile } from './steps/profilePage';
import { extendAccess } from './steps/extendAccessPage';
import { settings } from './steps/settingsPage';
import { products } from './steps/productsPage';
import { newProduct } from './steps/newProduct';
import { productCard } from './steps/productCardPage';
import { dishesPage } from './steps/dishesPage';
import { newDishPage } from './steps/newDishPage';
import { dishCardPage } from './steps/dishCardPage';
import { editCardPage } from './steps/editDishPage';

export const gettingStartedTutorial: RawTutorial = {
  name: 'GettingStarted',
  isMultiPage: true,
  steps: [
    // Home Page
    ...main,

    // Institutions
    ...institution,

    // Alternative Institutions (if user already has an institution)
    ...alternativeInstitutionsPage,

    // Edit Institution
    ...editInstitution,

    // New Institution
    ...newInstitution,

    // Institution Details
    ...institutionCard,
    //Products page
    ...products,

    //New product page
    ...newProduct,

    // Products Deetails
    ...productCard,

    // Menus
    ...menus,

    // Removed Menus
    ...removedMenus,

    // Tutorial Menu Item
    ...singleMenuItem,

    // Menu Editing
    ...menuEditing,

    // New Menu Step 2
    ...menuComposition,

    //New menu compostion single item
    ...menuCompositionItem,

    //Menu preview page
    ...menuPreviewPage,

    // Dishes page
    ...dishesPage,

    //New dish page
    ...newDishPage,

    //Dish card page
    ...dishCardPage,

    //Edit dish page
    ...editCardPage,

    //Profile page
    ...profile,

    //Extend access page
    ...extendAccess,

    //Settings page
    ...settings,
  ],
};

import React from 'react';
import { RawTutorialStep } from '../../types';
import { ReactComponent as Right } from '@Svg/arrow-right.svg';
import { ReactComponent as Left } from '@Svg/arrow-left.svg';
import { ReactComponent as X } from '@Svg/x.svg';
import { ReactComponent as Move } from '@Svg/move.svg';
import { ReactComponent as Salad } from '@Svg/salad.svg';

export const main: RawTutorialStep[] = [
  {
    route: '/',
    content: {
      title: 'Witaj w Intendent Pro!',
      text: [
        'Pokażę Ci jak korzystać z aplikacji, w której szybko ',
        'ułożysz własne jadłospisy zgodne z przepisami prawa.',
        <br />,

        <div className="flex justify-center">
          <Left />
          <Right />
        </div>,
        'strzałki oznaczają poprzedni lub kolejny krok',

        <X />,

        'zamyka samouczka',
        <Move />,
        'przytrzymaj okno, aby je przesunąć',
      ],
    },
  },
  {
    route: '/',
    id: 'main_page_news',
    content: {
      title: 'Na Stronie Głównej',
      text: [
        <Salad className="text-primary-500" />,
        'dowiesz się co nowego w aplikacji',
        <Salad className="text-primary-500" />,
        'przeczytasz ciekawe artykuły ',
        <Salad className="text-primary-500" />,
        'uzyskasz wiedzę z zakresu obowiązujących ',
        'przepisów prawa, normach żywienia i zasad dietetyki',
      ],
    },
    target: {
      selector: '#main-page-news',
      padding: 10,
    },
  },
  {
    route: '/',
    content: {
      title: 'Artykuły',
      text: ['Treść artykułu przeczytasz po prawej stronie.'],
    },
    target: {
      selector: '#main-page-article',
      padding: 10,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
  },
  {
    route: '/',
    id: 'facilities_link',
    content: {
      title: 'Placówki',
      text: [
        'W tej zakładce dodasz nową placówkę i zedytujesz jej dane.',
        <br />,
        <span className="font-medium text-primary-500">Kliknij w przycisk „Placówki” w głównym menu.</span>,
      ],
    },
    target: {
      selector: '#facilities-link',
      padding: 3,
    },
    onMount: ({ targetElement, startLoading, stopLoading }) => {
      if (!targetElement) return;

      const handleClick = () => {
        startLoading();
      };
      targetElement.addEventListener('click', handleClick);

      return () => {
        targetElement.addEventListener('click', handleClick);
        stopLoading();
      };
    },

    canGoNext: false,
  },
];

import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const dishCardPage: RawTutorialStep[] = [
  {
    route: '/dishes/details/{*}',
    content: {
      title: 'Karta potrawy',
      text: [''],
    },
    target: {
      selector: '#details-header',
      padding: { top: 25, left: 10, bottom: 10, right: -450 },
    },
    positioning: {
      align: 'center',
      justify: 'center',
    },
    previous: 'dish-tab-next-button',
  },
  {
    route: '/dishes/details/{*}',
    content: {
      title: 'Zamiennik alergenu',
      text: ['Z poziomu Karty potrawy wybierzesz zamienniki potraw dla diet.'],
    },
    target: {
      selector: '#alternative-dish-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement }) => subscribeToDisableOnHover({ targetElement }),
  },
  {
    route: '/dishes/details/{*}',
    content: {
      text: [<span className="font-bold text-primary-500">Kliknij w Opcje.</span>],
    },
    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, unlockDropdown, nextStep }) => {
      unlockDropdown();
      return subscribeToNextStep({
        targetElement,
        nextStep,
      });
    },
    canGoNext: false,
  },
  {
    route: '/dishes/details/{*}',
    content: {
      title: 'Powiel',
      text: [
        'Powiel potrawę, jeśli chcesz do Bazy własnej dodać podobną potrawę. Na uzupełniony formularz nanieś tylko potrzebne zmiany i zapisz dane. W ten sposób szybciej poszerzysz Bazę własną. ',
      ],
    },
    target: {
      selector: '#duplicate-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      return subscribeToDisableOnHover({ targetElement });
    },
  },
  {
    route: '/dishes/details/{*}',
    content: {
      title: 'Wydruki potrawy',
      text: ['Z poziomu Karty potrawy wydrukujesz jej przepis oraz recepturę do Księgi HACCP.'],
    },
    target: {
      selector: '#print-haccp-button',

      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      return subscribeToDisableOnHover({ targetElement });
    },
  },
  {
    route: '/dishes/details/{*}',
    content: {
      title: 'Dobrze wiedzieć, że...',
      text: [
        'Jeśli chcesz dostosować potrawę dedykowaną innej placówce zwiększając/ zmniejszając wielkość porcji tak, aby pasowała do Twojej placówki, edytuj potrawę z Bazy własnej lub powiel potrawę z Bazy Intendent Pro.',
        <span className=" text-primary-500">Kliknij w przycisk „Powiel”.</span>,
      ],
    },
    target: {
      selector: '#duplicate-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    canGoNext: false,
  },
];

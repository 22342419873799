import React from 'react';
import { array } from 'yup';
import { menuSelectedMealsInterface } from '@Interfaces/menuSelectedMealsInterface';
import { DietsInterface } from '@Interfaces/dietsInterface';
import { AlternativeType } from '@Types/AlternativeType';

export interface MenusListItemInterface {
  id: number;
  uuid: string;
  calorific: number;
  duration: number;
  folder: { id: number; name: string };
  institution: {
    id: number;
    type: string;
    active: boolean;
    has_current_norm_exists?: boolean;
  };
  name: string;
  updatedAt: string;
  shared: string;
  has_current_norm: boolean;
  type?: 'VIRTUAL_NUTRITIONIST' | 'ORDINARY';
}

export interface MenuDetailsInterface {
  author: string | boolean;
  id: number;
  name: string;
  duration: number;
  calorific: number;
  institution: {
    name: string;
    type: string;
    active: boolean;
  };
  folder: {
    id: number;
    name: string;
  };
  criteria_met: boolean;
  has_latest_norm: boolean;
  type: 'VIRTUAL_NUTRITIONIST' | 'ORDINARY';
}

export interface MenuCompositionContextInterface {
  menu: MenuCompositionInterface;
  currentDayMenu: MenuCompositionDayInterface;
  daysSwitcher: {
    days: string[];
    start: string;
    end: string;
    currentDay: string;
    setStart: React.Dispatch<React.SetStateAction<string>>;
    setEnd: React.Dispatch<React.SetStateAction<string>>;
    setCurrentDay: React.Dispatch<React.SetStateAction<string>>;
  };
  countCriteriaSatisfied: () => number;
  countCriteriaAvailable: () => number;
  openItem: (type: 'dish' | 'product', id: number) => void;
  addItemToMenu: (
    menu: MenuCompositionInterface,
    meal: MenuCompositionMealInterface,
    item: any,
    force?: boolean
  ) => void; //TODO: doprecyzować typ
  changeItem: (
    menu: MenuCompositionInterface,
    meal: MenuCompositionMealInterface,
    oldItem: any,
    item: any,
    force?: boolean
  ) => void; //TODO: doprecyzować typ
  deleteItem: (menu: MenuCompositionInterface, meal: MenuCompositionMealInterface, item: any) => void;
  addSubItem: (menu: MenuCompositionInterface, meal: MenuCompositionMealInterface, dish: any, item: any) => void;
  resetDish: (menu: MenuCompositionInterface, meal: MenuCompositionMealInterface, item: any) => void; //TODO: doprecyzować typ
  changeSubItem: (
    menu: MenuCompositionInterface,
    meal: MenuCompositionMealInterface,
    item: any,
    oldItem: any,
    subItem: any
  ) => void; //TODO: doprecyzować typ
  changePositionDown: (menuId: number, mealId: number, itemId: number) => void;
  changePositionUp: (menuId: number, mealId: number, itemId: number) => void;
  changeWeight: (menuId: number, mealId: number, itemId: number, weight: number) => void;
  submit: () => void;
  updateMenu: (menu) => void;
  editMode: boolean;
  processing: boolean;
  findOnMenuDay: number;
  tab: string;
  setTab: (tab: string) => void;
  previousItem: any;
  setPreviousItem: (previousItem: any) => void;
  changePlannedSizeOfServing: (menuId: number, mealId: number, itemId: number, plannedSizeOfServing: number) => void;
}

export interface MenuCompositionCriteriaInterface {
  at_least_three_milk_products?: boolean;
  at_least_two_milk_products?: boolean;
  daily_protein: boolean;
  drink_sugar: boolean;
  every_meal_fruit_or_vegetable: boolean;
  every_meal_wheat: boolean;
  more_vegetables_than_fruits: boolean;
}

export interface MenuCompositionNumberNormsInterface {
  carbohydrates: MenuCompositionNormInterface;
  energy: MenuCompositionNormInterface;
  fats: MenuCompositionNormInterface;
  protein: MenuCompositionNormInterface;
}

export interface MenuCompositionBooleanNormsInterface {
  carbohydrates: boolean;
  energy: boolean;
  fats: boolean;
  protein: boolean;
}

export interface MenuCompositionNormInterface {
  min: number;
  max: number;
}

export interface MenuCompositionDayInterface {
  energy: number;
  protein: number;
  fats: number;
  carbohydrates: number;
  meals: MenuCompositionMealInterface[];
}

export interface MenuCompositionMealInterface {
  actual_energy: number;
  id: number;
  items: Record<string, MenuCompositionItemInterface>;
  required_energy: number;
  type: 'BREAKFAST' | 'SECOND_BREAKFAST' | 'DINNER' | 'AFTERNOON_TEA' | 'SUPPER' | 'SNACK';
}

export interface MenuCompositionItemInterface {
  id: number;
  itemId: number;
  name: string;
  type: 'dish' | 'product';
  energy: number;
  protein: number;
  fats: number;
  carbohydrates: number;
  position: number;
  weight?: number;
  is_dirty?: boolean;
  planned_size_of_serving: number;
  items?: MenuCompositionSubItemInterface[];
  possiblesAlternatives: AlternativeType;
}

export interface MenuCompositionSubItemInterface {
  id: number;
  itemId: number;
  name: string;
  type: 'dish' | 'product';
  energy: number;
  protein: number;
  fats: number;
  carbohydrates: number;
  position: number;
  weight: number;
  unit?: string;
}

export interface MenuCompositionInterface {
  menu_list: [];
  calorific: number;
  criteria: Record<string, MenuCompositionCriteriaInterface>;
  criteria_met: boolean;
  duration: number;
  folder: { name: string };
  id: number;
  institution: {
    name: string;
    type:
      | 'NURSERY'
      | 'KINDERGARTEN'
      | 'SCHOOL'
      | 'KINDERGARTEN_AND_NURSERY_COMPLEX'
      | 'INSTITUTION_WITH_ALL_DAY_NUTRITION'
      | 'CARE_AND_EDUCATIONAL_COMPLEX';
    norms: MenuCompositionNumberNormsInterface;
  };
  days: MenuCompositionDayInterface[];
  name: string;
  norms: Record<string, MenuCompositionBooleanNormsInterface>;
  norms_met: boolean;
  norms_met_per_day: Record<string, boolean>;
  type: 'ORDINARY' | 'VIRTUAL_NUTRITIONIST';
  diets: {
    dairyFree: boolean;
    eggFree: boolean;
    glutenFree: boolean;
    vegetarian: boolean;
    elimination: boolean;
  };
  possiblesAlternatives: {
    dairyFree: possibleAlternative;
    eggFree: possibleAlternative;
    glutenFree: possibleAlternative;
    vegetarian: possibleAlternative;
    elimination: possibleAlternative;
  };
  diet_type: AlternativeType;
  parent_menu?: number;
}

interface possibleAlternative {
  id: number | null;
  name: string | null;
  alternative_dish_id: number | null;
}

type WeekDays = {
  FRIDAY: boolean;
  MONDAY: boolean;
  SATURDAY: boolean;
  SUNDAY: boolean;
  THURSDAY: boolean;
  TUESDAY: boolean;
  WEDNESDAY: boolean;
};

class Menu {
  private readonly _name: string;
  private readonly _institutionId: number;
  private readonly _folderId: number;
  private readonly _start: Date;
  private readonly _end: Date;
  private readonly _includeDaysOff: boolean;
  private readonly _includeHolidays: boolean;
  private readonly _type: 'ORDINARY' | 'VIRTUAL_NUTRITIONIST';
  private readonly _vegetarianDays?: WeekDays;
  private readonly _fishDay?: WeekDays;
  private readonly _selectedMeals?: menuSelectedMealsInterface[];
  private readonly _diets: DietsInterface;
  private readonly _parentMenuId: number | null;

  constructor(
    name: string,
    institutionId: number,
    folderId: number,
    start: Date,
    end: Date,
    includeDaysOff: boolean,
    includeHolidays: boolean,
    type: 'ORDINARY' | 'VIRTUAL_NUTRITIONIST',
    vegetarianDays?: WeekDays,
    fishDay?: WeekDays,
    selectedMeals?: menuSelectedMealsInterface[],
    diets: DietsInterface,
    parentMenuId: number | null
  ) {
    this._name = name;
    this._institutionId = institutionId;
    this._folderId = folderId;
    this._start = start;
    this._end = end;
    this._includeDaysOff = includeDaysOff;
    this._includeHolidays = includeHolidays;
    this._type = type;
    this._vegetarianDays = vegetarianDays;
    this._fishDay = fishDay;
    this._selectedMeals = selectedMeals;
    this._diets = diets;
    this._parent_menu_id = parentMenuId;
  }

  get name(): string {
    return this._name;
  }

  get institutionId(): number {
    return this._institutionId;
  }

  get folderId(): number {
    return this._folderId;
  }

  get start(): Date {
    return this._start;
  }

  get end(): Date {
    return this._end;
  }

  get includeDaysOff(): boolean {
    return this._includeDaysOff;
  }

  get includeHolidays(): boolean {
    return this._includeHolidays;
  }

  get type(): 'ORDINARY' | 'VIRTUAL_NUTRITIONIST' {
    return this._type;
  }

  get vegetarianDays(): WeekDays | undefined {
    if (this.type === 'ORDINARY') {
      return undefined;
    }
    return this._vegetarianDays;
  }

  get fishDay(): WeekDays | undefined {
    if (this.type === 'ORDINARY') {
      return undefined;
    }

    return this._fishDay;
  }

  get selectedMeals() {
    if (this.type === 'ORDINARY') {
      return undefined;
    }

    const selectedMeals = {};
    const tempSelectedMeals = { ...this._selectedMeals };

    Object.values(tempSelectedMeals).forEach((meal, index) => {
      const tempIds = [];
      Object.values(meal.culinarySets).forEach((culinarySet) => {
        tempIds.push({ id: culinarySet.id });
      });
      selectedMeals[index] = { name: meal.name, culinarySets: tempIds };
    });

    return selectedMeals;
  }

  get diets() {
    if (this.type === 'VIRTUAL_NUTRITIONIST') {
      return undefined;
    }
    return this._diets;
  }

  get parentMenuId() {
    return this._parent_menu_id;
  }
}

export default Menu;

import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import styles from './Modal.module.scss';
import ModalCloseButton from '../../LegacyComponents/buttons/ModalCloseButton';
import Heading3 from '../Typography/Heading3';
import ConfettiModal from '@Components/Modal/ConfettiModal';
import clsx from 'clsx';

export interface Props {
  id?: string;
  isOpen: boolean;
  title?: string;
  titleItem?: React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
  width?: number;
  height?: number | string;
  color?: string;
  classNameChildren?: string;
  confettiModal?: boolean;
}

export default function Modal(props: Props) {
  return (
    <>
      <ReactModal
        id="modal"
        isOpen={props.isOpen}
        onRequestClose={props?.onClose}
        shouldCloseOnOverlayClick={true}
        portalClassName={'z-100'}
        style={{
          overlay: {
            backgroundColor: 'rgba(40, 80, 77, 0.70)',
            zIndex: 11,
          },
          content: {
            width: `${props.width}px`,
            height: typeof props.height === 'string' ? props.height : `${props.height}px`,
            margin: 'auto',
            borderRadius: '10px',
            padding: '24px',
            overflow: 'hidden',
            zIndex: '1000',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: props.alignItems ?? 'center',
            height: '100%',
          }}
        >
          <div style={{ width: '100%' }} className={props.classNameChildren} id={props.id}>
            <div className={styles.header}>
              {props?.titleItem ? (
                props.titleItem
              ) : (
                <Heading3 className={'w-full'} color={props?.color ?? '#26282C'}>
                  {props.title}
                </Heading3>
              )}

              {props?.onClose && <ModalCloseButton onClose={props.onClose} />}
            </div>

            {props.children}
          </div>
        </div>
        {props?.confettiModal && <ConfettiModal />}
      </ReactModal>
    </>
  );
}

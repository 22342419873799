import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const removedMenus: RawTutorialStep[] = [
  {
    id: 'menus-removed-list-no-menus',
    route: '/menus/removedList',
    content: {
      title: 'Kosz',
      text: ['Brak usuniętych jadłospisów.', <span className="text-primary-500">Kliknij w przycisk „Kosz”.</span>],
    },
    next: 'return-to-menu-list-button',
  },
  {
    id: 'menus-removed-list-dropdown-menu',
    route: '/menus/removedList',
    content: {
      title: 'Kosz',
      text: [
        'W razie omyłkowego usunięcia jadłospisu, ',
        'możesz umieścić go z powrotem na liście jadłospisów',
        <span className="text-primary-500">Kliknij w opcje (3 kropki),</span>,
      ],
    },
    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    canGoNext: false,
    previous: 'menu-trash-button',
  },
  {
    id: 'restore-menu-option',
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus/removedList',
    content: {
      title: 'Przywrócenie jadłospisu',
      text: ['Funkcja „Przywróć” służy do umieszczenia jadłospisu z powrotem na głównej liście.'],
    },
    target: {
      selector: '#menu-list-restore-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      lockDropdown();

      return () => {
        unsubscribe();
      };
    },
    skipSaveState: true,
  },
  {
    id: 'return-to-menu-list-button',
    route: '/menus/removedList',
    content: {
      title: 'Wyjście z kosza',
      text: [<span className="text-primary-500">Kliknij w przycisk „Wróć do jadłospisów”.</span>],
    },
    target: {
      selector: '#return-to-menu-list-button',
      padding: 3,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();

      return () => null;
    },
    previous: 'menus-removed-list-dropdown-menu',
    canGoNext: false,
  },
];

import Loader from 'react-loader-spinner';
import Paragraph from '@Components/Typography/Paragraph';
import React from 'react';
import Modal from '@Components/Modal/Modal';

export default function LoadingIndicatorFullScreen({ progress = false }: Props) {
  if (!progress) {
    return (<></>);
  }

  return (
    <Modal key={'pending-modal'} title={'Trwa generowanie jadlospisu...'} isOpen={true} height={200} width={360}>
      <div id={'pending_indicator'} className={'flex flex-col  justify-between h-28'}>
        <Loader type="TailSpin" height="64px" width="64px" className={'self-center'} />
        <Paragraph className={'text-xl font-semibold mt-6'}>
          Może to zająć do ok. 1 minuty
        </Paragraph>
      </div>
    </Modal>
  );
}

interface Props {
  progress?: boolean;
}
import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToClickWithLoading } from '../../utils/subscribeToClick';

export const institution: RawTutorialStep[] = [
  {
    route: '/institutions',
    id: 'add-institution',
    content: {
      title: 'Nowa placówka',
      text: [
        'Teraz wspólnie utworzymy Twoją pierwszą placówkę.',
        <span className="text-primary-500">Kliknij w przycisk „Dodaj placówkę”</span>,
      ],
    },
    target: {
      selector: '#add-institution',
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, startLoading, stopLoading }) => {
      if (!targetElement) return;

      const unsubscribe = subscribeToClickWithLoading({
        targetElement,
        startLoading,
        stopLoading,
      });

      return unsubscribe;
    },
    next: 'edit-institution-name',
    canGoNext: false,
  },
];

import React, { useState } from 'react';
import Modal from '../../../../Components/Modal/Modal';

import PrimaryButton from '../../../../Components/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Components/Buttons/SecondaryButton';
import { Inertia } from '@inertiajs/inertia';
import ParagraphBold from '../../../../Components/Typography/ParagraphBold';

const ModalNoInstitution = ({ open }) => {
  const [isOpen, setIsOpen] = useState(open);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={'Brak utworzonej placówki.'}
      width={570}
      height={240}
      isOpen={isOpen}
      alignItems={'normal'}
      onClose={() => Inertia.visit(route('menus.list'))}
      classNameChildren="flex flex-col"
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <ParagraphBold>
            Przed dodaniem jadłospisu konieczne jest utworzenie placówki.
          </ParagraphBold>
        </div>
        <div className={`flex flex-row justify-start`}>
          <PrimaryButton
            label={'Utwórz placówkę'}
            style={{ width: 208, marginRight: 16 }}
            onClick={() => Inertia.visit(route('institutions.create'))}
          />
          <SecondaryButton
            type={'submit'}
            onClick={() => Inertia.visit(route('menus.list'))}
            label={'Zamknij'}
            style={{ width: 208, marginRight: 16 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalNoInstitution;

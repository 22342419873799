import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const newProduct: RawTutorialStep[] = [
  {
    id: 'new-product-name-input',
    route: '/products/new',
    content: {
      title: 'Nazwa produktu',
      text: ['Wpisz nazwę produktu. ', <b className="font-semibold ">Dla Samouczka wpisz - produkt testowy.</b>],
    },

    target: {
      selector: '#new-product-name-input',
    },
  },
  {
    route: '/products/new',
    content: {
      title: 'Waga produktu',
      text: [
        'Wybierz rodzaj wagi, w której wprowadzisz produkt do Bazy własnej.',
        <span>
          <b className="font-semibold ">Waga netto</b> - to masa produktu wraz z odpadem (np. banan ze skórką).
        </span>,
        <span>
          <b className="font-semibold ">Waga rynkowa</b> - to najczęściej wybierany typ wagi przy dodawaniu nowych
          produktów.
        </span>,
        'Wykorzystywana do tworzenia zapotrzebowania z jadłospisu (czyli listy zakupów).',
      ],
    },
    target: {
      selector: '#product-weight-type-container',
    },
  },
  {
    id: 'product-weight-type-container',
    route: '/products/new',
    content: {
      title: 'Odpad',
      text: [
        'Jeśli wykorzystujesz produkt w całości, np. mleko, olej, nasiona słonecznika,',
        'to odpad wynosi 0 g. Jeśli jednak wprowadzasz produkt, który potrzebuje obróbki wstępnej',
        '(np. świeży owoc), zważ go przed obróbką oraz jego odpad po obróbce. Wpisz wyniki obu mas.',
        'Program odejmie wagę odpadu od wagi rynkowej, aby prawidłowo wyliczyć wartości odżywcze produktu z wagi netto.',
        'Jeśli nie znasz wagi odpadu sprawdź, czy w bazie Intendent Pro nie znajduje się już podobny produkt z wpisaną wagą odpadu. Skopiuj ją.',
        <b className="font-semibold ">Dla samouczka wpisz wagę 100 g, odpad 0 g.</b>,
      ],
    },
    target: {
      selector: '#new-product-waste-radio-buttons',
    },
  },
  {
    route: '/products/new',
    content: {
      title: 'Jednostka miary',
      text: ['Wybierz odpowiednią jednostkę miary dla nowego produktu.'],
    },
    target: {
      selector: '#product-units-container',
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
  },
  {
    id: 'product-nutritional-values',
    route: '/products/new',
    content: {
      title: 'Wartości odżywcze produktu',
      text: [
        'Przepisz z etykiety wartości odżywcze dla 100 g produktu.',
        'Pola Sól i Sód są połączone. Wystarczy wpisać wartość w jednym polu, a drugie pole uzupełni się automatycznie.',
        <b className="font-semibold ">Dla samouczka wpisz do każdego pola wartość 1.</b>,
      ],
    },
    target: {
      selector: '#product-nutritional-values',
    },
  },
  {
    route: '/products/new',
    content: {
      title: 'Skład produktu',
      text: [
        <span>
          Dla produktu <b className="font-semibold">jednoskładnikowego</b> nie trzeba wpisywać składu.
        </span>,
        <span>
          Skład produktu <b className="font-semibold">wieloskładnikowego</b> przepisz z etykiety
        </span>,
        ' wraz z alergenami.',
        'Przykładowo, dla chleba orkiszowego wpisz skład: ',
        'mąka orkiszowa (z pszenicy), mąka pszenna, zakwas orkiszowy (z pszenicy), sól.',
        'Alergeny w nawiasach zostaną później wyróżnione na wydruku jadłospisu zgodnie z przepisami prawa. ',
        'Skład produktów umieszczany na jadłospisie może być wymagany podczas kontroli Sanepidu. ',
        <b className="font-semibold">Dla samouczka wybierz opcję: Produkt jednoskładnikowy.</b>,
      ],
    },
    target: {
      selector: '#new-product-composition-section',
    },
    onMount: ({ targetElement }) => {
      if (!targetElement) return;

      const option = targetElement.querySelector('#product-type-multi-component-option') as HTMLElement;

      option?.click();

      return () => null;
    },
  },
  {
    route: '/products/new',
    content: {
      title: 'Alergeny',
      text: [
        'Oznacz alergeny zawarte w produkcie.',
        <b className="font-semibold">Dla samouczka wybierz alergeny: mleko i seler.</b>,
      ],
    },
    target: {
      selector: '#product-allergens',
    },
    onNext: ({ targetElement }) => {
      if (!targetElement) return;

      const milkCheckbox = targetElement.querySelector('input[name="milk"]') as HTMLInputElement;
      const celeryCheckbox = targetElement.querySelector('input[name="celery"]') as HTMLInputElement;

      if (milkCheckbox && !milkCheckbox.checked) milkCheckbox.click();
      if (celeryCheckbox && !celeryCheckbox.checked) celeryCheckbox.click();
    },
  },
  {
    id: 'product-group',
    route: '/products/new',
    content: {
      title: 'Grupa produktów',
      text: [
        'Przypisz nowy produkt do grupy.',
        <b className="font-semibold">Dla samouczka wybierz pierwszą grupę z góry.</b>,
      ],
    },
    target: {
      selector: '#product-group',
    },
  },
  {
    route: '/products/new',
    content: {
      title: 'Rodzaj ',
      text: ['Ustal, czy produkt jest wegetariański, bezglutenowy, bezmleczny lub bezjajeczny.'],
    },
    target: {
      selector: '#product-kinds',
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    id: 'product-tags',
    route: '/products/new',
    content: {
      title: 'Sezonowość produktu',
      text: [
        'Oznacz pory roku, w których wykorzystujesz produkt do przyrządzania posiłków.',
        <b className="font-semibold ">Dla Samouczka oznacz tag: całoroczny</b>,
      ],
    },
    target: {
      selector: '#product-tags',
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    id: 'alternatives-replacement-item-0',
    route: '/products/new',
    content: {
      title: 'Zamiennik alergenu',
      text: [
        'Wybierz produkt na taki, który spełnia kryteria diety.',
        <span className=" text-primary-500">Kliknij w przycisk „Wybierz produkt”.</span>,
      ],
    },
    target: {
      selector: '#alternatives-replacement-item-0',
      padding: {
        top: 40,
        right: 80,
        bottom: 10,
      },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep }) => {
      if (!targetElement) return;
      const button = targetElement.querySelector('button') as HTMLElement;
      const skipButton = targetElement.querySelector('#alternatives-skip-item-button') as HTMLElement;
      const changeButton = targetElement.querySelector('#alternatives-set-item-button') as HTMLElement;

      const nextStepCleanup = subscribeToNextStep({ targetElement: button, nextStep });
      const disableCleanup = subscribeToDisableOnHover({ targetElement: skipButton });
      const changeDishCleanup = subscribeToNextStep({ targetElement: changeButton, nextStep });

      return () => {
        nextStepCleanup?.();
        disableCleanup?.();
        changeDishCleanup?.();
      };
    },
    canGoNext: false,
  },
  {
    route: '/products/new',
    content: {
      title: 'Wybierz produkt z listy ',
      text: [
        'Zamień produkt nabiałowy dla diety bezmlecznej. ',
        <span className=" text-primary-500">Dla Samouczka wybierz: Napój migdałowy klikając w znak „+”.</span>,
      ],
    },
    target: {
      selector: '#modal',
      padding: 0,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep }) => {
      const closeButton = targetElement?.querySelector('button');
      const listItems = document?.querySelectorAll('.infinite-scroll-component div') as NodeListOf<HTMLElement>;

      console.log(targetElement?.querySelector('div'));
      const cleanupFunctions: (() => void)[] = [];

      if (closeButton) {
        const closeCleanup = subscribeToNextStep({
          targetElement: closeButton,
          nextStep,
        });
        cleanupFunctions.push(closeCleanup);
      }

      if (listItems) {
        listItems.forEach((item) => {
          const stepCleanup = subscribeToNextStep({
            targetElement: item,
            nextStep,
          });
          cleanupFunctions.push(stepCleanup);
        });
      }

      return () => {
        cleanupFunctions.forEach((cleanup) => cleanup?.());
      };
    },
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
    canGoNext: false,
  },
  {
    route: '/products/new',
    content: {
      title: 'Pomiń w potrawie',
      text: [
        'Jeśli produkt zostanie oznaczony jako pominięty, nie pojawi się w składnikach potrawy na jadłospisie, a także na wydrukach dla rodziców i dla kucharzy. Opcja ta jest pomocna, jeśli chcesz pominąć np. seler w zupach w diecie eliminacyjnej.',
        <br />,
        <span className="text-primary-500">Dla Samouczka kliknij w przycisk „Pomiń w potrawie”.</span>,
      ],
    },
    target: {
      selector: '#alternatives-replacement-item-1',
      padding: {
        right: 80,
        bottom: 10,
      },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep }) => {
      if (!targetElement) return;
      const setButton = targetElement.querySelector('#alternatives-set-item-button') as HTMLElement;
      const skipButton = targetElement.querySelector('#alternatives-skip-item-button') as HTMLElement;

      const nextStepCleanup = subscribeToNextStep({ targetElement: skipButton, nextStep });
      const disableCleanup = subscribeToDisableOnHover({ targetElement: setButton });

      return () => {
        nextStepCleanup?.();
        disableCleanup?.();
      };
    },
    canGoNext: false,
    previous: 'alternatives-replacement-item-0',
  },
  {
    route: '/products/new',
    content: {
      title: 'Produkt jako potrawa',
      text: [
        'Uzupełnij formularz potrawy, jeśli produkt może służyć jako gotowa potrawa (jest to np. jabłko, jogurt owocowy). ',
        'Jeśli nie, przejdź dalej.',
      ],
    },
    target: {
      selector: '#product-can-be-used-as-dish',
    },
    onMount: ({ targetElement }) => {
      if (!targetElement) return;

      const checkbox = targetElement.querySelector('input') as HTMLInputElement;

      if (!checkbox) return;

      checkbox.disabled = true;

      return () => {
        checkbox.disabled = false;
      };
    },
  },
  {
    id: 'product-new-product-form-submit',
    route: '/products/new',
    content: {
      title: 'Zapisz formularz',
      text: [
        <span className="text-primary-500">
          Sprawdź, czy wszystkie dane są poprawne i kliknij w przycisk &quot;Zapisz&quot;.
        </span>,
      ],
    },
    target: {
      selector: '#submit-button',
    },
    canGoNext: false,
  },
];

import React from 'react';
import CheckHasLatestInstitutionInMenu from '@Components/Modal/CheckHasLatestInstitutionInMenu';
import UserMustDuplicateMenuModal from '@Components/Modal/UserMustDuplicateMenuModal';
import LoaderModal from '@Components/Modal/LoaderModal';

export default function ModalsRedux() {
  return (
    <>
      <CheckHasLatestInstitutionInMenu />
      <UserMustDuplicateMenuModal />
      <LoaderModal />
    </>
  );
}

import Menu from "../Menu";
import axios, {AxiosResponse} from "axios";

const FETCH_MENU_BY_UUID_URL = '/api/v2/menus';

class MenuFetcher {
    static fetchMenuId(uuid: string): Promise<number> {
        return axios
            .get(`${FETCH_MENU_BY_UUID_URL}/${uuid}`)
            .then(response => {
                return response.data.menu.id
            })
    }

    static fetchMenuById(id: number): Promise<AxiosResponse<any>> {
        return axios
            .get(`${FETCH_MENU_BY_UUID_URL}/${id}`)
    }
}

export default MenuFetcher

import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToClick } from '../../utils/subscribeToClick';

export const editCardPage: RawTutorialStep[] = [
  {
    id: 'dish-age-range-container',
    route: '/dishes/edit/{*}',
    content: {
      title: 'Przelicz gramatury',
      text: [
        <span>
          Zmień <b className="font-semibold ">Przedział wiekowy</b> na inny.
        </span>,
      ],
    },
    target: {
      selector: '#dish-age-range-container',
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ unlockDropdown, targetElement }) => {
      unlockDropdown();

      return () => null;
    },
    onNext: () => {
      const tabButton = document.querySelector('#tab-button-Składniki-i-przepis') as HTMLButtonElement;
      tabButton?.click();
    },
    next: 'recalculate-button',
  },

  //------------------------------------------------Składniki i przepisy ----------------------------------------------------
  {
    route: '/dishes/edit/{*}',
    content: {
      text: ['Abu wrócić do ustawień. ', <span className=" text-primary-500">Kliknij w przycisk „Ustawienia”.</span>],
    },
    target: {
      selector: '#tab-button-Ustawienia',
      padding: 10,
    },

    canGoPrevious: false,
    onMount: ({ targetElement, previousStep }) => {
      if (!targetElement) return;
      const handleClick = () => {
        previousStep();
      };

      return subscribeToClick({
        targetElement,
        onClick: handleClick,
      });
    },
  },
  {
    id: 'recalculate-button',
    route: '/dishes/edit/{*}',
    content: {
      title: 'WORK IN PROGRESS 0',
      text: [''],
    },
    target: {
      selector: '#dish-planned-size-of-servings-container',
      padding: 10,
    },
  },
  {
    route: '/dishes/edit/{*}',
    content: {
      title: 'WORK IN PROGRESS 1',
      text: [''],
    },
    target: {
      selector: '#dish-planned-size-of-servings-container',
      padding: 10,
    },
  },
  {
    route: '/dishes/edit/{*}',
    content: {
      title: 'WORK IN PROGRESS 2',
      text: [''],
    },
    target: {
      selector: '#dish-planned-size-of-servings-container',
      padding: 10,
    },
  },
  {
    route: '/dishes/edit/{*}',
    content: {
      text: [
        'W "Profilu" zmodyfikujesz dane Twojego konta.',
        ' dane dotyczące konta.',
        <span className=" text-primary-500">Kliknij w przycisk „Podgląd”.</span>,
      ],
    },
    target: {
      selector: '#profile-link-top-bar',
      padding: { right: -20 },
      ignoreNavbarOffset: true,
    },
    positioning: {
      align: 'bottom',
      justify: 'center',
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();
      return () => null;
    },
    canGoNext: false,
  },
];

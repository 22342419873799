import React from 'react';
import { RawTutorialStep } from '../../types';

export const profile: RawTutorialStep[] = [
  {
    id: 'profile-page',
    route: '/profile',
    content: {
      title: 'Dane konta',
      text: [
        'Na tej stronie zmienisz swoje dane, adres email, hasło',

        'oraz sprawdzisz datę wygaśnięcia ważności konta.',
      ],
    },
    target: {
      selector: '#profile-page',
      padding: { top: 0, right: 5, bottom: 10, left: 5 },
    },
  },
  {
    id: 'profile-page-extend-access-link',
    route: '/profile',
    content: {
      text: [<span className="font-semibold text-primary-500">Kliknij w przycisk „Przedłuż dostęp”</span>],
    },
    target: {
      selector: '#extend-access-link',
      padding: 3,
    },
    onMount: ({ targetElement, startLoading, stopLoading }) => {
      if (!targetElement) return;

      const handleClick = () => {
        startLoading();
      };
      targetElement.addEventListener('click', handleClick);

      return () => {
        targetElement.addEventListener('click', handleClick);
        stopLoading();
      };
    },

    canGoNext: false,
    next: 'extend-access-page',
  },
  {
    id: 'profile-last-step-for-admin',
    route: '/profile',
    content: {
      title: 'Koniec samouczka dla konta administroatora',
      text: [],
    },
    canGoNext: false,
    next: null,
    previous: 'profile-page',
  },
];

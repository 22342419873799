import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const menuComposition: RawTutorialStep[] = [
  {
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Kalendarz',
      text: ['Kalendarz pozwala na przenoszenie się między dniami jadłospisu.'],
    },
    target: {
      selector: '#menu-days-switcher',
      padding: 5,
    },
  },
  {
    id: 'menu-days-dropdown-button',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Opcje przenoszenia całych dni jadłospisu',
      text: [<span className="text-primary-500">Kliknij w przycisk „Opcje” (3 kropki).</span>],
    },
    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      if (!targetElement) return;
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    canGoNext: false,
  },
  {
    id: 'menu-day-copy-button',
    dropdownId: 'menu-days-switcher-day-0',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Posiłki z całego dnia możesz:',
      text: ['Kopiować- do innego dnia.'],
    },
    target: {
      selector: '#menu-day-copy-button',
      padding: 0,
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    id: 'menu-day-clear-button',
    dropdownId: 'menu-days-switcher-day-0',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Posiłki z całego dnia możesz:',
      text: ['Wyczyścić- wszystkie posiłki zostaną usunięte. Dzień zostanie pusty.'],
    },
    target: {
      selector: '#menu-day-clear-button',
      padding: 0,
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    id: 'menu-day-transfer-button',
    dropdownId: 'menu-days-switcher-day-0',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Posiłki z całego dnia możesz:',
      text: ['Przenieść- do innego dnia. Przy czym dzień, z którego przenosisz posiłki zostanie pusty.'],
    },
    target: {
      selector: '#menu-day-transfer-button',
      padding: 0,
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    id: 'menu-day-change-button',
    dropdownId: 'menu-days-switcher-day-0',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Posiłki z całego dnia możesz:',
      text: ['Zamieniać dni- program zamieni posiłki między dniami.'],
    },
    target: {
      selector: '#menu-day-change-button',
      padding: 0,
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    id: 'menu-composition-criteria-title',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Sprawdź Jadłospis',
      text: [
        'Program kontroluje Twój jadłospis zgodnie z rozporządzeniem Ministra Zdrowia z dnia 26 lipca 2016 r. w sprawie grup środków',
        ' spożywczych przeznaczonych do sprzedaży dzieciom i młodzieży w jednostkach systemu',
        ' oświaty oraz wymagań, jakie muszą spełniać środki spożywcze stosowane w ramach żywienia zbiorowego',
        ' dzieci i młodzieży w tych jednostkach.',
        <br />,
        <span className="text-primary-500">Kliknij przycisk „Sprawdź”.</span>,
      ],
    },
    target: {
      selector: '#menu-composition-criteria-title',
      padding: {
        top: 35,
        left: 40,
        bottom: 5,
        right: 25,
      },
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    canGoNext: false,
    previous: 'menu-days-dropdown-button',
  },
  {
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Podczas układania jadłospisu regularnie sprawdzaj, czy spełnia on wymogi rozporządzenia.',
      text: [],
    },
    target: {
      selector: '#modal',
      padding: 5,
    },
    positioning: {
      justify: 'center',
      align: 'top',
    },
    onMount: ({ targetElement, nextStep }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      const unsubscribe = subscribeToNextStep({
        targetElement: button,
        nextStep,
      });

      return () => {
        unsubscribe();
      };
    },
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
  },
  {
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Norma posiłku',
      text: [
        <span className="font-semibold ">Norma Dnia</span>,
        'To średnioważone normy żywieniowe, które zostały obliczone',
        'na podstawie danych w zakładce Placówki.',
        <span className="font-semibold ">Aktualnie</span>,
        'To aktualna ilość kalorii i składników odżywczych w danym dniu jadłospisu.',
        'Wartości podświetlają się na zielono, jeśli mieszczą się w widełkach powyższych norm dnia.',
        'Jeśli podświetlają się na czerwono, oznacza, że wykraczają poza normy.',
      ],
    },
    target: {
      selector: '#menu-composition-norms-container',
    },
    previous: 'menu-composition-criteria-title',
  },
  {
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Pamiętaj!',
      text: [
        'Jadłospis powinien spełniać normę dla energii (kcal) każdego dnia. ',
        'Nomy dla pozostałych składników (białek, tłuszczów i węglowodanów)',
        'możesz zrealizować w jadłospisie dekadowym.',
        'Zobacz, gdzie sprawdzisz realizację norm dla makroskładników w całym jadłospisie.',
      ],
    },
  },
  {
    id: 'macronutrients-container',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Realizacja norm dla makroskładników',
      text: [
        'Kończąc układanie jadłospisu, sprawdź, czy makroskładniki',
        ' zostały zbilansowane.',
        <span className="text-primary-500">Kliknij w przycisk „Pokaż”.</span>,
      ],
    },
    target: {
      selector: '#macronutrients-container',
      padding: 5,
    },
    positioning: {
      justify: 'right',
      align: 'center',
    },
    onMount: ({ nextStep }) => {
      const targetElement = document.querySelector('#show-macronutrients-button') as HTMLElement;

      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unsubscribe();
      };
    },
    canGoNext: false,
  },
  {
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Porównaj realizację zapotrzebowania na makroskładniki do norm.',
      text: [],
    },
    target: {
      selector: '#modal',
      padding: 5,
    },
    positioning: {
      justify: 'center',
      align: 'top',
    },
    onMount: ({ targetElement, nextStep }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      const unsubscribe = subscribeToNextStep({
        targetElement: button,
        nextStep,
      });

      return () => {
        unsubscribe();
      };
    },
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
  },
  {
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Norma posiłku',
      text: ['to proponowana wartość energii dla posiłku. Niewielkie odchylenia są dopuszczalne.'],
    },
    target: {
      selector: '#menu-composition-meal-norms',
      padding: 5,
    },
    previous: 'macronutrients-container',
  },
  {
    id: 'culinaryset-save-meal',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Zachęcamy do tworzenia zestawów',
      text: [
        'Jeśli ułożysz smaczny posiłek, który spełnia normy',
        'i często pojawia się w Twoich jadłospisach,',
        <span>
          zapisz go jako <b className="font-semibold ">zestaw.</b>
        </span>,
      ],
    },
    target: {
      selector: '#culinaryset-save-meal',
      padding: 5,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    id: 'culinaryset-delete-meal',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Z zestawami szybciej!',
      text: [
        'Przy układaniu kolejnego menu zestawy przyspieszą Twoją pracę!',
        'Uzupełnisz posiłki własnymi zestawami.',
      ],
    },
    target: {
      selector: '#culinaryset-delete-meal',

      padding: 5,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
    next: 'menu-composition-meal-item-order-buttons',
  },
  {
    id: 'culinaryset-delete-meal-no-valid-meal',
    route: ['/menus/composition/{*}', '/menus/composition/{*}/{*}'],
    content: {
      title: 'Z zestawami szybciej!',
      text: [
        'Przy układaniu kolejnego menu zestawy przyspieszą Twoją pracę!',
        'Uzupełnisz posiłki własnymi zestawami.',
      ],
    },
    target: {
      selector: '#culinaryset-delete-meal',

      padding: 5,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
    previous: 'culinaryset-save-meal',
    next: 'preview-button-no-valid-menu',
  },
];

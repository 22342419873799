import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Inertia } from '@inertiajs/inertia';
import MenuCreator from '../Services/MenuCreator';
import Menu, { MenuCompositionMealInterface } from '../Menu';
import MenuUpdater from '../Services/MenuUpdater';
import MenuFetcher from '../Services/MenuFetcher';
import { toast } from 'react-toastify';
import { usePage } from '@inertiajs/inertia-react';
import ModalNoInstitution from '../Modals/ModalNoInstitution';
import { menuSelectedMealsInterface } from '@Interfaces/menuSelectedMealsInterface';
import LoadingIndicatorFullScreen from '@Components/Indicator/LoadingIndicatorFullScreen';
import axios from 'axios';
import { CulinarySetInterface } from '@Interfaces/culinarySetInterface';
import useCheckHasLatestInstitutionInMenuModal from '@/Hooks/useCheckHasLatestInsitutionInMenuModal';

export const MenuFormContext = React.createContext({});

export const MenuFormProvider = (props) => {
  const { showModal } = useCheckHasLatestInstitutionInMenuModal();

  const recalculation = props.page?.recalculation ?? false;
  const duplication = props.page?.duplication ?? false;

  const pageProps = usePage().props;
  const [progress, setProgress] = useState<boolean>(false);
  const [recalculationMeals, setRecalculationMeals] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedMeals, setSelectedMeals] = useState<menuSelectedMealsInterface[]>([]);
  const [isChangedCulinarySet, setIsChangedCulinarySet] = useState(false);
  const [countDays, setCountDays] = useState(props?.page?.menu?.day_count ?? 0);
  const getInstitutions = (withOutId = -1) => {
    return props.page.institutions
      .filter((institution) => institution.id !== withOutId && institution.active === true)
      .map((institution) => {
        return {
          value: institution.id,
          label: institution.name,
          has_current_norm_exists: institution.has_current_norm_exists,
          diets: {
            dairyFree: institution.diets_dairy_free,
            eggFree: institution.diets_egg_free,
            elimination: institution.diets_elimination,
            glutenFree: institution.diets_gluten_free,
            vegetarian: institution.diets_vegetarian,
          },
        };
      });
  };

  const getMenuDayOptions = (menuDaysOptions) => {
    const days = {
      MONDAY: false,
      TUESDAY: false,
      WEDNESDAY: false,
      THURSDAY: false,
      FRIDAY: false,
      SATURDAY: false,
      SUNDAY: false,
    };

    Object.values(menuDaysOptions)?.forEach((menuDaysOption) => {
      days[Object.keys(menuDaysOption)[0]] = true;
    });

    return days;
  };

  const handleClearMenuDayOptions = (type: 'fishDay' | 'vegetarianDays') => {
    const days = {
      MONDAY: false,
      TUESDAY: false,
      WEDNESDAY: false,
      THURSDAY: false,
      FRIDAY: false,
      SATURDAY: false,
      SUNDAY: false,
    };

    const tempMenu = { ...menu };
    tempMenu[type] = days;
    setMenu(tempMenu);
  };

  const loadMenu = useMemo(() => {
    if (!props.page?.menu) {
      return {
        id: undefined,
        name: '',
        institutionId: null,
        folderId: null,
        start: '',
        end: '',
        includeDaysOff: false,
        includeHolidays: false,
        institutionRecalculationId:
          props.page?.menu !== undefined ? getInstitutions(props.page?.menu?.id)[0] : undefined,
        type: props.page.type,
        vegetarianDays: props.page?.vegetarianDays ?? {
          MONDAY: false,
          TUESDAY: false,
          WEDNESDAY: false,
          THURSDAY: false,
          FRIDAY: false,
          SATURDAY: false,
          SUNDAY: false,
        },
        fishDay: props.page?.fishDay ?? {
          MONDAY: false,
          TUESDAY: false,
          WEDNESDAY: false,
          THURSDAY: false,
          FRIDAY: false,
          SATURDAY: false,
          SUNDAY: false,
        },
        diets: {
          dairyFree: false,
          eggFree: false,
          glutenFree: false,
          vegetarian: false,
          elimination: false,
        },
      };
    }

    const fishDays = [...(props?.page?.menu?.fishDay ?? [])];
    const vegetarianDays = [...(props?.page?.menu?.vegetarianDays ?? [])];

    const menu = { ...props?.page?.menu };

    menu['fishDay'] = getMenuDayOptions(fishDays);
    menu['vegetarianDays'] = getMenuDayOptions(vegetarianDays);
    if (recalculation) {
      menu['name'] = '';
    }

    return menu;
  }, [props.page?.menu, props.page.type, props.page?.vegetarianDays, props.page?.fishDay]);

  const [menu, setMenu] = useState(loadMenu);

  const isVirtualNutritionist = useMemo(() => {
    return menu.type === 'VIRTUAL_NUTRITIONIST';
  }, [menu.type]);

  const isOrdinary = useMemo(() => {
    return menu.type === 'ORDINARY';
  }, menu.type);

  const [errors, setErrors] = useState({
    name: undefined,
    institutionId: undefined,
    folderId: undefined,
    start: undefined,
    end: undefined,
    includeDaysOff: undefined,
    includeHolidays: undefined,
    institutionRecalculationId: undefined,
    recalculationMeals: undefined,
    type: undefined,
    vegetarianDays: undefined,
    fishDay: undefined,
  });

  useEffect(() => {
    if (recalculation) {
      setErrors(pageProps.errors);
    }
  }, [pageProps.errors]);

  const findInstitutionById = (id: number) => {
    const institutions = getInstitutions();
    return institutions.find((element) => element.value == id);
  };

  const getDefaultInstitution = () => {
    const institutions = getInstitutions();

    const findInstitution = findInstitutionById(menu.institutionId);

    if (findInstitution === undefined) {
      return institutions[0] ?? { name: null, value: null };
    }

    return findInstitution;
  };

  const getDefaultFolder = () => {
    const folders = getFolders();

    let findFolder = folders.find((element) => element.value === menu.folderId);

    if (findFolder === undefined) {
      findFolder = folders.find((element) => element.name === 'Ogólny');

      if (findFolder === undefined) {
        return folders[0];
      }

      return findFolder;
    }

    return findFolder;
  };

  const cleanError = (error) => {
    if (error === 'institutionRecalculationId' && recalculation) {
      setErrors({});
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    cleanError(name);
    setMenu({
      ...menu,
      [name]: value,
    });
  };

  const handleDateRangeChange = (
    start?: DateTime,
    end?: DateTime,
    includeDaysOff?: boolean,
    includeHolidays?: boolean
  ) => {
    setMenu({
      ...menu,
      start: start ? start.toISODate() : '',
      end: end ? end.toISODate() : '',
      includeDaysOff: includeDaysOff ?? false,
      includeHolidays: includeHolidays ?? false,
    });
  };

  const handleChangeDayOptions = (type: 'vegetarianDays' | 'fishDay', name) => {
    setMenu({ ...menu, [type]: { ...menu[type], [name]: !menu[type][name] } });
  };

  const checkMenuState = (menuId: number, isModified: boolean) => {
    setProgress(true);
    let attempts = 0;
    const maxAttempts = 60;
    const interval = 1000;

    const intervalId = setInterval(async () => {
      try {
        const response = await axios.post(route('menus.checkMenuState', { menu: menuId }));
        if (response.data.state === 'READY') {
          toast.success('Wygenerowano jadłospis');
          clearInterval(intervalId);
          let responseData;

          if (isModified) {
            responseData = {
              numberOfDaysArranged: response.data.numberOfDaysArranged,
            };
          } else {
            responseData = {};
          }

          Inertia.visit(route('menus.editMenuVirtualDietitian', { menu: menuId }), {
            data: responseData,
            onFinish: () => {
              setProgress(false);
            },
          });
        }
      } catch (error) {
        toast.error('Błąd podczas sprawdzania stanu jadłospisu');
        clearInterval(intervalId);
        setProgress(false);
      }

      attempts++;
      if (attempts >= maxAttempts) {
        clearInterval(intervalId);
        toast.error('Błąd podczas przetwarzania');
        setProgress(false);
      }
    }, interval);
  };

  const checkModified = () => {
    if (menu.type === 'ORDINARY' || !menu?.id) return false;

    const oldMenu = loadMenu;

    const attributes = ['institutionId', 'folderId', 'start', 'end', 'includeDaysOff', 'includeHolidays', 'type'];

    for (const attr of attributes) {
      if (menu[attr] !== oldMenu[attr]) {
        return true;
      }
    }

    const checkDays = ['vegetarianDays', 'fishDay'];

    for (const attr of checkDays) {
      if (menu[attr] !== oldMenu[attr]) {
        const keys = Object.keys(oldMenu[attr]);
        keys.forEach((key) => {
          if (menu[attr][key] !== oldMenu[attr][key]) {
            return true;
          }
        });
      }
    }
    return isChangedCulinarySet ?? false;
  };
  const submit = () => {
    setProgress(true);
    let currentInstitution = getInstitutions().find((element) => element.value == menu.institutionId);
    if (!currentInstitution.has_current_norm_exists) {
      showModal(currentInstitution.value);
      setProgress(false);
      return;
    }
    const menuToSave = new Menu(
      menu.name,
      menu.institutionId,
      menu.folderId,
      menu.start,
      menu.end,
      menu.includeDaysOff,
      menu.includeHolidays,
      menu.type,
      menu.vegetarianDays,
      menu.fishDay,
      selectedMeals,
      menu.diets
    );

    if (recalculation) {
      setProgress(true);
      currentInstitution = getInstitutions().find((element) => element.value == menu.institutionRecalculationId);
      if (!currentInstitution.has_current_norm_exists) {
        showModal(currentInstitution.value);
        setProgress(false);
        return;
      }
      Inertia.post(route('menus.recalculation.store'), {
        name: menuToSave._name,
        institutionId: menuToSave._institutionId,
        folderId: menuToSave._folderId,
        start: menuToSave._start,
        end: menuToSave._end,
        includeDaysOff: menuToSave._includeDaysOff,
        includeHolidays: menuToSave._includeHolidays,
        type: menuToSave._type,
        vegetarianDays: menuToSave._vegetarianDays,
        fishDay: menuToSave._fishDay,
        selectedMeals: menuToSave._selectedMeals,
        diets: menuToSave._diets,
        parentMenuId: menuToSave._parentMenuId,
        institutionRecalculationId: menu.institutionRecalculationId,
        menuId: menu.id,
        recalculationMeals: recalculationMeals,
      });
      setProgress(false);
      return;
    }
    const isModified = checkModified();

    if (menu.id) {
      MenuUpdater.update(menu.id, menuToSave, isModified)
        .then((response) => {
          toast.success('Jadłospis został zapisany');
          if (isVirtualNutritionist) {
            if (response.status === 200) {
              checkMenuState(response.data.menuId, isModified);
            } else {
              toast.error(response.data.error);
            }
          } else {
            Inertia.visit(`/menus/composition/${menu.id}`);
          }
        })
        .catch((e) => {
          const status = e.response.status;

          if (status === 422) {
            toast.error('Niepoprawne dane formularza');
            setErrors(e.response.data.errors);
            return;
          }
          toast.error('Nie udało się zapisać jadłospisu');
        });
    } else {
      MenuCreator.create(menuToSave)
        .then((response) => {
          if (isVirtualNutritionist) {
            if (response.status === 200) {
              checkMenuState(response.data.menuId, true);
            } else {
              toast.error(response.data.error);
            }
          } else {
            MenuFetcher.fetchMenuId(response.data.menu.uuid).then((id) => {
              Inertia.visit(`/menus/composition/${id}`);
            });
          }
          toast.success('Nowy jadłospis został utworzony');
        })
        .catch((e) => {
          const status = e?.response?.status;
          if (status === 422) {
            toast.error('Niepoprawne dane formularza');

            setErrors(e.response.data.errors);
            return;
          }

          toast.error('Nie udało się zapisać jadłospisu');
        });
    }
    setProgress(false);
  };

  const cancel = () => {
    Inertia.visit('/menus');
  };

  const getCommonPartMealDistributions = async () => {
    const institutions = props.page.institutions;
    const recalculationInstitution = institutions.find((element) => element.id == menu.institutionRecalculationId);

    const commonParts = [{ type: 'ALL', value: false, position: -1 }];
    Object.values(menu.meals)?.forEach((element) => {
      const commonPart = recalculationInstitution?.meal_distribution.find((e) => e.type == element);
      if (commonPart === undefined) return;
      commonParts.push({ type: commonPart.type, value: false, position: commonPart.position });
    });

    if (commonParts.length === 2) {
      commonParts[1].value = true;
    }

    const sortedCommonParts = commonParts.sort((a, b) => a.position - b.position);

    setRecalculationMeals(sortedCommonParts);
  };

  useEffect(async () => {
    await getCommonPartMealDistributions();
  }, [menu.institutionRecalculationId]);

  const handleMealsCheckbox = (event) => {
    const mealType = event.target.name;
    const foundIndex = recalculationMeals.findIndex((element) => element.type === mealType);

    if (foundIndex !== -1) {
      setRecalculationMeals((prevMeals) => {
        const updatedMeals = [...prevMeals];
        updatedMeals[foundIndex] = {
          type: mealType,
          value: !updatedMeals[foundIndex].value,
        };
        return updatedMeals;
      });
    } else {
      setRecalculationMeals((prevMeals) => [...prevMeals, { type: mealType, value: true }]);
    }
  };

  const getFolders = () => {
    return props.page.folders.map((folder) => {
      return { value: folder.id, label: folder.name };
    });
  };

  const setCurrentMenu = () => {
    const currentMenu = menu;
    if (pageProps.oldType !== undefined && pageProps.oldType !== null) {
      currentMenu.institutionId = pageProps.institutions.find(
        (institution) => institution.type === pageProps.oldType
      )?.id;
      if (currentMenu.institutionId === undefined) {
        currentMenu.institutionId = pageProps.institutions[0].id ?? null;
      }
    } else if (!props.page?.menu?.institutionId && props.page?.institutions && props.page.institutions.length > 0) {
      currentMenu.institutionId = props.page.institutions[0].id ?? null;
    } else {
      currentMenu.institutionId = getDefaultInstitution().value;
    }

    if (!props.page?.menu?.folderId && props.page?.folders && props.page.folders.length > 0) {
      currentMenu.folderId = props.page.folders[0].id ?? null;
    } else {
      currentMenu.folderId = getDefaultFolder().value;
    }

    setMenu(currentMenu);
  };

  const getTitle = () => {
    if (recalculation) return 'Przeliczanie jadłospisu';
    if (pageProps.duplication) return 'Powielanie jadłospisu';
    if (menu.id) {
      return 'Edycja jadłospisu';
    } else {
      return 'Nowy jadłospis';
    }
  };

  useEffect(() => {
    setTitle(getTitle());
    recalculation &&
      setMenu({
        ...menu,
      });
    setCurrentMenu();
  }, []);

  useEffect(async () => {
    await getCommonPartMealDistributions();
  }, []);

  useEffect(() => {
    const mealDistributions =
      props.page.institutions.find((item) => item.id == menu.institutionId)?.meal_distribution || [];

    const formattedMeals = mealDistributions.map((meal) => ({
      name: meal.type,
      culinarySets:
        menu?.menuCulinarySets
          ?.filter((menuCulinarySet) => {
            return menuCulinarySet.type === meal.type;
          })
          .map((menuCulinarySet) => menuCulinarySet.culinarySet) ?? [],
    }));

    setSelectedMeals(formattedMeals);
  }, [menu.institutionId]);

  const handleAddCulinarySetToMeal = (meal, item) => {
    const culinarySetExists = selectedMeals.some((selectedMeal) =>
      selectedMeal.culinarySets.some((culinarySet) => {
        if (culinarySet.id === item.id) {
          toast.error('Zestaw już istnieje');
          return true;
        }
        return false;
      })
    );

    if (culinarySetExists) {
      return;
    }

    const indexFindMeal = selectedMeals.findIndex((tempMeal) => tempMeal.name === meal.name);

    if (indexFindMeal <= -1) {
      toast.error('Nie znaleziono danego posiłku');
      return;
    }

    const updatedMeal = {
      ...selectedMeals[indexFindMeal],
      culinarySets: [...selectedMeals[indexFindMeal].culinarySets, item],
    };

    const updatedMeals = selectedMeals.map((tempMeal, index) => (index === indexFindMeal ? updatedMeal : tempMeal));

    toast.success('Dodano zestaw');

    setSelectedMeals(updatedMeals);
    if (!isChangedCulinarySet) {
      setIsChangedCulinarySet(true);
    }
  };

  const handleSwapCulinarySetInMeal = (meal, newItem, swapItem) => {
    const indexFindMeal = selectedMeals.findIndex((tempMeal) => tempMeal.name === meal.name);

    if (indexFindMeal <= -1) {
      toast.error('Nie znaleziono danego posiłku');
      return;
    }
    const culinarySets = selectedMeals[indexFindMeal].culinarySets.map((set) => (set === swapItem ? newItem : set));

    const updatedMeal = {
      ...selectedMeals[indexFindMeal],
      culinarySets,
    };

    const updatedMeals = selectedMeals.map((tempMeal, index) => (index === indexFindMeal ? updatedMeal : tempMeal));

    setSelectedMeals(updatedMeals);
  };

  const handleRemoveCulinarySetInMeal = (meal: MenuCompositionMealInterface, culinarySet: CulinarySetInterface) => {
    const culinarySetId = culinarySet.id;
    const mealName = meal.name;
    setSelectedMeals((prevMeals) => {
      const mealIndex = prevMeals.findIndex((meal) => meal.name === mealName);

      if (mealIndex === -1) {
        toast.error('Błąd kasowania: nie znaleziono danego posiłku');
        return prevMeals;
      }

      const meal = prevMeals[mealIndex];

      const updatedCulinarySets = meal.culinarySets.filter((set) => set.id !== culinarySetId);

      if (updatedCulinarySets.length === meal.culinarySets.length) {
        toast.error('Zestaw kulinarny nie został znaleziony w posiłku');
        return prevMeals;
      }

      const updatedMeal = {
        ...meal,
        culinarySets: updatedCulinarySets,
      };

      const updatedMeals = [...prevMeals.slice(0, mealIndex), updatedMeal, ...prevMeals.slice(mealIndex + 1)];

      setIsChangedCulinarySet(true);

      toast.success('Zestaw kulinarny został usunięty');

      return updatedMeals;
    });
  };

  const countMaxCulinarySetsInMeal = useMemo(() => {
    return Math.ceil((countDays + 1) * 1.2);
  }, [countDays]);

  const handleSetDiets = (e) => {
    const { name, checked } = e.target;
    setMenu((prev) => ({
      ...prev,
      diets: {
        ...prev.diets,
        [name]: checked,
      },
    }));
  };

  useEffect(() => {
    if (loadMenu.institutionId == menu.institutionId) {
      setMenu((prev) => ({
        ...prev,
        diets: {
          ...loadMenu.diets,
        },
      }));
    } else {
      const foundInstitution = findInstitutionById(menu.institutionId);
      setMenu((prev) => ({
        ...prev,
        diets: {
          ...prev.diets,
          ...foundInstitution.diets,
        },
      }));
    }
  }, [menu.institutionId]);

  return (
    <MenuFormContext.Provider
      value={{
        menu: menu,
        institutions: props.page.institutions,
        folders: props.page.folders,
        errors: errors,
        handleInputChange,
        handleDateRangeChange: handleDateRangeChange,
        submit: submit,
        progress: progress,
        cancel: cancel,
        recalculation: recalculation,
        getInstitutions: getInstitutions,
        mealDistributions: props.page?.mealDistributions,
        recalculationMeals: recalculationMeals,
        handleMealsCheckbox: handleMealsCheckbox,
        getDefaultFolder: getDefaultFolder,
        getDefaultInstitution: getDefaultInstitution,
        getFolders: getFolders,
        title: title,
        duplication: duplication,
        handleChangeDayOptions: handleChangeDayOptions,
        selectedMeals: selectedMeals,
        handleAddCulinarySetToMeal: handleAddCulinarySetToMeal,
        setCountDays: setCountDays,
        countDays: countDays,
        handleRemoveCulinarySetInMeal: handleRemoveCulinarySetInMeal,
        handleSwapCulinarySetInMeal: handleSwapCulinarySetInMeal,
        handleClearMenuDayOptions: handleClearMenuDayOptions,
        startingTab: props.page?.tab ?? 0,
        setErrors: setErrors,
        countMaxCulinarySetsInMeal: countMaxCulinarySetsInMeal,
        handleSetDiets: handleSetDiets,
        isVirtualNutritionist: isVirtualNutritionist,
        isOrdinary: isOrdinary,
      }}
    >
      {props.children}
      {!recalculation && !duplication && <LoadingIndicatorFullScreen progress={progress} />}
      <ModalNoInstitution open={getInstitutions().length === 0} />
    </MenuFormContext.Provider>
  );
};

import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const products: RawTutorialStep[] = [
  {
    route: '/products',
    content: {
      title: 'Wyszukiwarki',
      text: ['Szybkie wyszukiwanie produktów z Bazy Intendent Pro lub Bazy własnej oraz po nazwie produktu.'],
    },
    positioning: {
      align: 'bottom',
      justify: 'center',
    },
    target: {
      selector: '#menus-list-filters',
    },
  },
  {
    route: '/products',
    content: {
      title: 'Opcje produktu',
      text: [<span className=" text-primary-500">Kliknij w opcje (3 kropki)</span>],
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },

    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      if (!targetElement) return;
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    canGoNext: false,
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/products',
    content: {
      title: 'Podgląd',
      text: ['Przejście do karty produktu.'],
    },
    target: {
      selector: '#menu-composition-subitem-dropdown-item-view-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/products',
    content: {
      title: 'Powiel',
      text: [
        'Powiel istniejący produkt, a w wypełnionym formularzu wprowadź już tylko potrzebne zmiany, np. nazwy lub wartości odżywczych.',
      ],
    },
    target: {
      selector: '#duplicate-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/products',
    content: {
      title: 'Dodaj nowy produkt',
      text: [
        'Dodasz teraz przykładowy produkt do bazy własnej.',
        <span className=" text-primary-500">Kliknij w przycisk „Dodaj produkt”</span>,
      ],
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },

    target: {
      selector: '#add-product',
      padding: 5,
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();

      return () => null;
    },
    canGoNext: false,
  },
];

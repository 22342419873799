import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToNextStep } from '../../utils/subscribeToClick';

export const editInstitution: RawTutorialStep[] = [
  {
    id: 'edit-institution-name',
    route: '/institutions/edit/{*}',
    content: {
      title: 'Nazwa placówki',
      text: [
        'Edycja nazwy placówki.',
        'Jeśli planujesz jadłospisy dla więcej niż jednej jednostki, ',
        'np. zespołu szkolno- przedszkolnego, ',
        'to w pierwszej kolejności uzupełnij dane przedszkola. ',
        'Później dodasz kolejną placówkę.',
      ],
    },
    target: {
      selector: '#institutions-name',
      focus: true,
      padding: { top: 37, bottom: 27 },
    },
    previous: 'add-institution',
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: 'Typ placówki',
      text: ['Edycja typu placówki.'],
    },
    target: {
      selector: '#institutions-type',
      focus: true,
      padding: { top: 40 },
    },
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: 'Liczba osób zapisanych na posiłki',
      text: [
        'Edycja liczby osób w odpowiednim przedziale wiekowym.',
        'Dane te posłużą do wyliczenia średnioważonych norm żywieniowych.',
      ],
    },
    target: {
      selector: '#people-distribution-container',
      padding: 20,
    },
  },
  {
    id: 'edit-institutions-meals-distribution',
    route: '/institutions/edit/{*}',
    content: {
      title: 'Procentowy rozkład energii',
      text: [
        'Edycja liczby posiłków serwowanych w placówce.',
        'Edycja nazwy posiłków.',
        'Kliknij w przycisk "Uzupełnij wartości procentowe" ',
      ],
    },
    target: {
      selector: '#institutions-meals-distribution-container',
      padding: 20,
    },
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: 'Wymagany poziom energii',
      text: [
        'Upewnij się, że posiłki pokrywają odpowiedni poziom zapotrzebowania żywionych osób na energię i składniki odżywcze.',
      ],
    },
    target: {
      selector: '#institutions-meals-distribution-summary',
      padding: 5,
    },
    previous: 'edit-institutions-meals-distribution',
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: <span className="font-bold text-primary-500">Kliknij w przycisk „Dalej”. </span>,
      text: [''],
    },
    target: {
      selector: '#tab-button-next',
      padding: 10,
    },
    canGoNext: false,
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    next: 'edit-tab-button-Dane-placówki',
  },
  //------------------------------------------------Dane Placówki----------------------------------------------------
  {
    route: '/institutions/edit/{*}',
    content: {
      text: ['Abu wrócić do ustawień. ', <span className=" text-primary-500">Kliknij w przycisk „Ustawienia”.</span>],
    },
    target: {
      selector: '#tab-button-Ustawienia',
      padding: 10,
    },

    canGoPrevious: false,
    onMount: ({ targetElement, previousStep }) => {
      if (!targetElement) return;
      const handleClick = () => {
        previousStep();
      };
      targetElement.addEventListener('click', handleClick);
      return () => {
        targetElement.removeEventListener('click', handleClick);
      };
    },
  },
  {
    id: 'edit-tab-button-Dane-placówki',
    route: '/institutions/edit/{*}',
    content: {
      title: 'Dodatkowe dane placówki',
      text: [
        <>
          Wypełnienie poniższego formularza <u>nie jest obowiązkowe.</u>
        </>,
      ],
    },
    target: {
      selector: '#tab-button-Dane-placówki',
      padding: 10,
    },
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: 'Diety w placówce',
      text: ['Wybierz rodzaje diet, które planujesz w placówce. '],
    },
    target: {
      selector: '#institutions-data-diets-in-institution',
      padding: 5,
    },
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: 'Dane do wydruku receptur do Księgi HACCP',
      text: [
        'Wpisz imię i nazwisko dyrektora placówki, autora receptur',
        'i szefa kuchni, jeśli chcesz drukować receptury ',
        'do swojej Księgi HACCP.',
        'Zawsze mozesz wrócić do edycji danych osób w placówce.',
      ],
    },
    target: {
      selector: '#institutions-data',
      padding: { top: -140 },
    },
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: 'Logo placówki',
      text: [
        'Załącz logo swojej placówki klikając w przycisk ',
        '„Dodaj zdjęcie”,',
        'jeśli chcesz, aby logo wyświetlało się na wydruku jadłospisu.',
        'Jeśli nie, przejdź Dalej.',
      ],
    },
    target: {
      selector: '#institution-logo',
    },
  },
  {
    route: '/institutions/edit/{*}',
    content: {
      title: 'Pamiętaj!',
      text: [
        'Zawsze możesz wrócić do edycji placówki.',
        <span className=" text-primary-500">Kliknij w przycisk &quot;Zapisz&quot;</span>,
      ],
    },
    target: {
      selector: '#tab-button-submit',
      padding: 10,
    },
    canGoNext: false,
    onMount: ({ targetElement, startLoading, stopLoading }) => {
      if (!targetElement) return;

      const handleClick = () => {
        startLoading();
      };
      targetElement.addEventListener('click', handleClick);

      return () => {
        targetElement.addEventListener('click', handleClick);
        stopLoading();
      };
    },
    next: 'institution-details',
  },
];

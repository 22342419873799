import axios, { AxiosResponse } from 'axios';
import Menu from '../Menu';
import { Inertia } from '@inertiajs/inertia';

const UPDATE_MENU_URL = '/api/menus/{menuId}';
const UPDATE_MENU_FOLDER_URL = '/api/v2/menus/{menuId}/{folderId}';

export default class MenuUpdater {
  static update(id: number, menu: Menu, recalculate = false): Promise<AxiosResponse<any>> {
    const url = UPDATE_MENU_URL.replace('{menuId}', id.toString());

    if (menu.type === 'VIRTUAL_NUTRITIONIST') {
      return axios.post(route('menus.updateWithVirtualDietitian', {menu: id}), {
        name: menu.name,
        institutionId: menu.institutionId,
        folderId: menu.folderId,
        start: menu.start,
        end: menu.end,
        includeDaysOff: menu.includeDaysOff,
        includeHolidays: menu.includeHolidays,
        type: menu.type,
        vegetarianDays: menu.vegetarianDays,
        fishDay: menu.fishDay,
        selectedMeals: menu.selectedMeals,
        recalculate: recalculate,
        diets: menu.diets
      });
    } else {
      return axios.patch(url, {
        name: menu.name,
        institutionId: menu.institutionId,
        folderId: menu.folderId,
        start: menu.start,
        end: menu.end,
        includeDaysOff: menu.includeDaysOff,
        includeHolidays: menu.includeHolidays,
        selectedMeals: menu.selectedMeals,
        diets: menu.diets
      });
    }
  }

  static moveToFolder(
    menuId: number,
    folderId: number
  ): Promise<AxiosResponse<any>> {
    const url = UPDATE_MENU_FOLDER_URL.replace(
      '{menuId}',
      menuId.toString()
    ).replace('{folderId}', folderId.toString());

    return axios.patch(url);
  }
}


import React, {CSSProperties} from "react"
import cx from "classnames";
import { clsx } from 'clsx';

type Props = {
    type: "submit" | "button" | "reset" | undefined,
    style?: CSSProperties | undefined;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    icon?: React.FunctionComponent<any>,
    label?: string
    disabled?: boolean
    isLoading?: boolean,
    className?: string
}

const SecondaryButton = (props: Props) => {
    return <button
        {...props}
        className={
            clsx(
                'text-paragraph-bold rounded outline-none border-none flex-none cursor-pointer p-4 bg-grayscale-100 flex items-center justify-center hover:bg-grayscale-200 focus:bg-grayscale-200 active:bg-grayscale-300 disabled:bg-grayscale-100 disabled:shadow-border-disabled disabled:text-grayscale-400 disabled:cursor-not-allowed',
                props.className
            )
        }
        disabled={props.disabled || props.isLoading}
    >
        {props.icon && React.createElement(props.icon, {className: cx({['mr-2']: props.icon && props.label})})}
        {props.label}
    </button>
}

export default SecondaryButton

import axios, { AxiosResponse } from 'axios';
import Menu from '../Menu';

export default class MenuCreator {
  static create(menu: Menu, recalculation = false): Promise<AxiosResponse<any>> {
    if (menu.type === 'VIRTUAL_NUTRITIONIST') {
      return axios.post(route('menus.storeWithVirtualDietitian'), {
        name: menu.name,
        institutionId: menu.institutionId,
        folderId: menu.folderId,
        start: menu.start,
        end: menu.end,
        includeDaysOff: menu.includeDaysOff,
        includeHolidays: menu.includeHolidays,
        type: menu.type,
        vegetarianDays: menu.vegetarianDays,
        fishDay: menu.fishDay,
        selectedMeals: menu.selectedMeals,
        diets: menu.diets
      });
    } else {
      return axios.post(route('menus.store'), {
        name: menu.name,
        institutionId: menu.institutionId,
        folderId: menu.folderId,
        start: menu.start,
        end: menu.end,
        includeDaysOff: menu.includeDaysOff,
        includeHolidays: menu.includeHolidays,
        type: menu.type,
        diets: menu.diets
      });
    }
  }
}

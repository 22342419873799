import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToNextStep } from '../../utils/subscribeToClick';

export const newInstitution: RawTutorialStep[] = [
  {
    id: 'institution-name',
    route: '/institutions/new',
    content: {
      title: 'Nazwa placówki',
      text: [
        'Wpisz nazwę swojej placówki. ',
        'Jeśli planujesz jadłospisy dla więcej niż jednej jednostki, ',
        'np. zespołu szkolno- przedszkolnego, ',
        'to w pierwszej kolejności uzupełnij dane przedszkola. ',
        'Później dodasz kolejną placówkę.',
      ],
    },
    target: {
      selector: '#institutions-name',
      focus: true,
      padding: { top: 37, bottom: 27 },
    },
    previous: 'add-institution',
  },
  {
    route: '/institutions/new',
    content: {
      title: 'Typ placówki',
      text: ['Wybierz z listy typ placówki.'],
    },
    target: {
      selector: '#institutions-type',
      focus: true,
      padding: { top: 40 },
    },
  },
  {
    route: '/institutions/new',
    content: {
      title: 'Liczba osób zapisanych na posiłki',
      text: [
        'Wpisz liczbę osób w odpiwednim oprzedzile wiekowym.',
        'Dane te posłużą do wyliczenia do wyliczenia średnioważonych norm żywieniowych.',
      ],
    },
    target: {
      selector: '#people-distribution-container',
      padding: 20,
    },
  },
  {
    id: 'institutions-meals-distribution',
    route: '/institutions/new',
    content: {
      title: 'Kaloryczność posiłków',
      text: [
        <span className="font-bold">1.</span>,
        'Najpierw podaj liczbę posiłków serwowanych w placówce.',
        <span className="font-bold">2.</span>,
        'Następnie określ ich nazwy,',
        <span className="font-bold">3.</span>,
        <>
          Na koniec kliknij w przycisk{' '}
          <span className="font-medium text-primary-500">„Uzupełnij wartości procentowe”</span>
        </>,
      ],
    },
    target: {
      selector: '#institutions-meals-distribution-container',
      padding: 20,
    },
  },
  {
    route: '/institutions/new',
    content: {
      title: 'Wymagany poziom energii',
      text: ['Upewnij się, że spełniasz wymagany poziom energii ', 'we wszystkich posiłkach.'],
    },
    target: {
      selector: '#institutions-meals-distribution-summary',
      padding: 5,
    },
    previous: 'institutions-meals-distribution',
  },
  {
    route: '/institutions/new',
    content: {
      title: <span className="font-bold text-primary-500">Kliknij w przycisk „Dalej”. </span>,
      text: [''],
    },
    target: {
      selector: '#tab-button-next',
      padding: 10,
    },
    canGoNext: false,
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    next: 'tab-button-Dane-placówki',
  },
  //------------------------------------------------Dane Placówki----------------------------------------------------
  {
    route: '/institutions/new',
    content: {
      text: ['Abu wrócić do ustawień. ', <span className=" text-primary-500">Kliknij w przycisk „Ustawienia”.</span>],
    },
    target: {
      selector: '#tab-button-Ustawienia',
      padding: 10,
    },

    canGoPrevious: false,
    onMount: ({ targetElement, previousStep }) => {
      if (!targetElement) return;
      const handleClick = () => {
        previousStep();
      };
      targetElement.addEventListener('click', handleClick);
      return () => {
        targetElement.removeEventListener('click', handleClick);
      };
    },
  },
  {
    id: 'tab-button-Dane-placówki',
    route: '/institutions/new',
    content: {
      title: 'Dodatkowe dane placówki',
      text: [
        <>
          Wypełnienie poniższego formularza <u>nie jest obowiązkowe.</u>
        </>,
      ],
    },
    target: {
      selector: '#tab-button-Dane-placówki',
      padding: 10,
    },
  },
  {
    route: '/institutions/new',
    content: {
      title: 'Diety w placówce',
      text: ['Wybierz rodzaj diet, które planujesz w placówce. '],
    },
    target: {
      selector: '#institutions-data-diets-in-institution',
      padding: 5,
    },
  },
  {
    route: '/institutions/new',
    content: {
      title: 'Receptury do Księgi HACCP',
      text: [
        'Wpisz imię i nazwisko dyrektora placówki, autora receptur',
        'i szefa kuchni, jeśli chcesz drukować receptury ',
        'do swojej Księgi HACCP.',
      ],
    },
    target: {
      selector: '#institutions-data',
      padding: { top: -140 },
    },
  },
  {
    route: '/institutions/new',
    content: {
      title: 'Logo placówki',
      text: [
        'Załącz logo placówki, jeśli chcesz, ',
        'aby wyświetlało się na wydruku jadłospisu.',
        <span className=" text-primary-500">Kliknij w przycisk „Dodaj zdjęcie”.</span>,
        'Jeśli nie, przejdź Dalej.',
      ],
    },
    target: {
      selector: '#institution-logo',
    },
  },
  {
    route: '/institutions/new',
    content: {
      title: 'Pamiętaj!',
      text: [
        'Zawsze możesz wrócić do edycji placówki.',
        <span className=" text-primary-500">Kliknij przycisk „Zapisz”.</span>,
      ],
    },
    target: {
      selector: '#tab-button-submit',
      padding: 10,
    },
    canGoNext: false,
    onMount: ({ targetElement, startLoading, stopLoading }) => {
      if (!targetElement) return;

      const handleClick = () => {
        startLoading();
      };
      targetElement.addEventListener('click', handleClick);

      return () => {
        targetElement.addEventListener('click', handleClick);
        stopLoading();
      };
    },
    next: 'institution-details',
  },
];

import React from 'react';
import { RawTutorialStep } from '../../types';

export const menus: RawTutorialStep[] = [
  {
    id: 'menus-page',
    route: '/menus',
    content: {
      title: 'Wyszukiwarka jadłospisów',
      text: [
        <span>
          W tym polu wyszukasz jadłospisy po <b className="font-semibold">nazwie.</b>
        </span>,
      ],
    },
    target: {
      selector: '#menu-filters-search',
      padding: 10,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    route: '/menus',
    content: {
      title: 'Wyszukiwarka jadłospisów ',
      text: [
        'Wyszukasz tylko te jadłospisy, które zostały przypisane',
        <span>
          do danej <b className="font-semibold">placówki.</b>
        </span>,
      ],
    },
    target: {
      selector: '#menus-institutions-filter',
      padding: {
        top: 10,
        left: 105,
        bottom: 10,
        right: 10,
      },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    route: '/menus',
    content: {
      title: 'Wyszukiwarka jadłospisów ',
      text: ['Wyszukasz wszystkie jadłospisy, które zawierają daną potrawę.'],
    },
    target: {
      selector: '#menu-filters-dish-name',
      padding: 5,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    route: '/menus',
    content: {
      title: 'Sortowanie jadłospisów',
      text: ['Sortowanie po dacie dodania, dacie ostatniej zmiany, ', 'kaloryczności, ilości dni lub liczby dni.'],
    },
    target: {
      selector: '#menu-filters-order-by',
      padding: 10,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    route: '/menus',
    content: {
      title: 'Foldery',
      text: [
        'Jeśli Twoja lista jadłospisów jest już długa, utwórz foldery.',
        'Posegreguj jadłospisy wg przynależności do placówki, pór roku lub rodzaju diet.',
      ],
    },
    target: {
      selector: '#menu-folder-filter-section',
      padding: 10,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    id: 'menu-trash-button',
    route: '/menus',
    content: {
      title: 'Kosz',
      text: [
        'Jeśli usuniesz jadłospis, zostanie on najpierw ',
        'przeniesiony do kosza.',
        'Łatwo możesz go przywrócić i ponownie edytować.',
        'Po 30 dniach przebywania w koszu jadłospis zostanie trwale usunięty z serwera.',

        <br />,

        <span className="text-primary-500">Kliknij w przycisk „Kosz”.</span>,
      ],
    },
    target: {
      selector: '#trash-button',
      padding: 3,
    },

    positioning: {
      justify: 'left',
    },
    onMount: ({ targetElement, startLoading, stopLoading }) => {
      if (!targetElement) return;

      const handleClick = () => {
        startLoading();
      };
      targetElement.addEventListener('click', handleClick);

      return () => {
        targetElement.addEventListener('click', handleClick);
        stopLoading();
      };
    },
    canGoNext: false,
  },
];
